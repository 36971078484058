import axios from 'axios';
import { HOST_API_KEY } from '../config-global';
// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: HOST_API_KEY });
axiosInstance.interceptors.response.use((response) => response, (error) => {
    if (error.response && error.response.status === 401) {
        window.location.reload();
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
});
export default axiosInstance;
