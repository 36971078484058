export function createDynamicURL(baseUrl, queryParams) {
    let url = `${baseUrl}?`;
    const searchQuery = Object.entries(queryParams.search || {})
        .filter(([key, value]) => value !== undefined && value !== '' && !Number.isNaN(value))
        .map(([key, value]) => `${key}:${value}`)
        .join(';');
    if (searchQuery) {
        url += `search=${searchQuery};`;
    }
    delete queryParams.search;
    Object.entries(queryParams).forEach(([key, value]) => {
        if (value !== undefined && value !== '' && !Number.isNaN(value)) {
            url += `&${key}=${value}`;
        }
    });
    url = url.replace(/&$/, '');
    return url;
}
